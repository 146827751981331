import React, { useState, useCallback, useEffect } from 'react';
import {
    Box,
    Typography,
    Card,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    Chip,
    Snackbar, Alert, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions,
} from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DataGrid } from "@mui/x-data-grid";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import dayjs from "dayjs";
import Layout from "../../Layout/Layout";
import API from "../../api/v1";
import useUserInfo from "../../hooks/userProfile";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// 檢視對話框組件
const UsageInspectionDialog = ({ open, handleClose, rowData }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const version = process.env.REACT_APP_PUBLISH_VERSION;

    const handleCopy = () => {
        const textToCopy = `
            檢視完整問答
            當前版本: ${version}
            問答識別 ID: ${rowData?.id || ''}
            使用者識別 ID: ${rowData?.userId || ''}
            日期: ${rowData?.date || ''}
            時間: ${rowData?.time || ''}
            提問: ${rowData?.question || ''}
            回答: ${rowData?.answer || ''}
            原始費用: $${rowData?.costUSD || ''}
            試算費用: NT$${rowData?.costTWD || ''}
            --------------------------------------------------
        `.trim();

        navigator.clipboard.writeText(textToCopy)
            .then(() => setSnackbarOpen(true))
            .catch(err => console.error('Copy failed:', err));
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>檢視完整問答</DialogTitle>
                <IconButton
                    onClick={handleCopy}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <ContentCopyIcon />
                </IconButton>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        <TextField
                            label="使用者識別 ID"
                            value={rowData?.userId || ''}
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <TextField
                                label="日期"
                                value={rowData?.date || ''}
                                fullWidth
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="時間"
                                value={rowData?.time || ''}
                                fullWidth
                                InputProps={{ readOnly: true }}
                            />
                        </Box>
                        <TextField
                            label="提問"
                            value={rowData?.question || ''}
                            fullWidth
                            multiline
                            rows={2}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="回答"
                            value={rowData?.answer || ''}
                            fullWidth
                            multiline
                            rows={8}
                            InputProps={{ readOnly: true }}
                        />
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <TextField
                                label="原始費用（美元）"
                                value={`$${rowData?.costUSD || ''}`}
                                fullWidth
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="試算費用（台幣）"
                                value={`NT$${rowData?.costTWD || ''}`}
                                fullWidth
                                InputProps={{ readOnly: true }}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        sx={{
                            backgroundColor: '#CBA660',
                            color: 'white',
                            '&:hover': { backgroundColor: '#B8935C' }
                        }}
                    >
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    已成功複製內容到剪貼板
                </Alert>
            </Snackbar>
        </>
    );
};

const UsageAndFees = () => {
    const { userInfo } = useUserInfo();
    const [dateRange, setDateRange] = useState([dayjs().subtract(30, 'day'), dayjs()]);
    const [periodSelect, setPeriodSelect] = useState(30);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [qaData, setQaData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [overview, setOverview] = useState({
        tracesCount: 0,
        totalModelCost: 0,
        usdToTWDRate: 32.5
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const fetchData = useCallback(async (newPage, newPageSize) => {
        setLoading(true);
        if (!userInfo?.organizations?.[0]?.id || !userInfo?.organizations?.[0]?.projects?.[0]?.id) {
            return;
        }
        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;
            const response = await API.getTraces({
                orgId,
                projectId,
                from_time: dateRange[0].format(),
                to_time: dateRange[1].format(),
                limit: newPageSize,
                page: newPage + 1
            });

            if (response.code === 0 && response.data) {
                setQaData(response.data.traces.map(trace => ({
                    id: trace.id,
                    date: dayjs(trace.timestamp).format('YYYY/MM/DD'),
                    time: dayjs(trace.timestamp).format('HH:mm:ss'),
                    userId: trace.sessionId,
                    question: trace.input,
                    answer: trace.output,
                    costUSD: trace.totalCostUSD.toFixed(3),
                    costTWD: trace.totalCostTWD.toFixed(1)
                })));
                setTotalItems(response.data.meta.totalItems);
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setSnackbarMessage('獲取數據失敗');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    }, [userInfo, dateRange]);

    // 添加新的函數來獲取概覽數據
    const fetchOverview = useCallback(async () => {
        if (!userInfo?.organizations?.[0]?.id || !userInfo?.organizations?.[0]?.projects?.[0]?.id) {
            return;
        }
        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;
            const response = await API.getTracesOverview({
                orgId,
                projectId,
                from_time: dateRange[0].format(),
                to_time: dateRange[1].format(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            });

            if (response.code === 0 && response.data) {
                setOverview({
                    tracesCount: response.data.tracesCount,
                    totalModelCost: response.data.totalModelCost,
                    usdToTWDRate: response.data.usdToTWDRate
                });
            }
        } catch (error) {
            console.error('Failed to fetch overview:', error);
            setSnackbarMessage('獲取統計數據失敗');
            setSnackbarOpen(true);
        }
    }, [userInfo, dateRange]);

    useEffect(() => {
        fetchData(paginationModel.page, paginationModel.pageSize);
        fetchOverview();
    }, [fetchData, fetchOverview, paginationModel.page, paginationModel.pageSize]);

    const initialize = () => {
        fetchData(paginationModel.page, paginationModel.pageSize).then(r => {});
        fetchOverview().then(r => {});
    }

    const handleDateRangeChange = (newDateRange) => {
        if (newDateRange[0] && newDateRange[1]) {
            setDateRange(newDateRange);
            // fetchData(1, paginationModel.pageSize).then(r => {});
        }
    };

    const handlePeriodSelectChange = (event) => {
        const days = event.target.value;
        setPeriodSelect(days);
        const end = dayjs();
        const start = end.subtract(days, 'day');
        setDateRange([start, end]);
        // fetchData(1, paginationModel.pageSize).then(r => {});
    };

    const handleViewClick = (params) => {
        setSelectedRow(params.row);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleExport = async () => {
        if (!userInfo?.organizations?.[0]?.id || !userInfo?.organizations?.[0]?.projects?.[0]?.id) {
            return;
        }
        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;
            const response = await API.exportTraces({
                orgId,
                projectId,
                from_time: dateRange[0].format(),
                to_time: dateRange[1].format(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                responseType: 'blob',
                includeCost: true
            });

            const blobData = response instanceof Blob ? response : response.data;
            const url = window.URL.createObjectURL(blobData);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `usage-fees_${dateRange[0].format('YYYY-MM-DD')}_${dateRange[1].format('YYYY-MM-DD')}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            setSnackbarMessage('檔案匯出成功');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Export failed:', error);
            setSnackbarMessage('檔案匯出失敗');
            setSnackbarOpen(true);
        }
    };

    const columns = [
        { field: 'date', headerName: '日期', width: 110 },
        { field: 'time', headerName: '時間', width: 110 },
        { field: 'userId', headerName: '使用者識別 ID', width: 150 },
        { field: 'question', headerName: '提問', flex: 1 },
        { field: 'answer', headerName: '回答', flex: 1.5 },
        {
            field: 'costUSD',
            headerName: '原始費用（美元）',
            width: 150,
            renderCell: (params) => `$${params.value}`
        },
        {
            field: 'costTWD',
            headerName: '試算費用（台幣）',
            width: 150,
            renderCell: (params) => `NT$${params.value}`
        },
        {
            field: 'actions',
            headerName: '',
            width: 100,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleViewClick(params)}
                    sx={{
                        backgroundColor: '#CBA660',
                        '&:hover': { backgroundColor: '#B8935C' }
                    }}
                >
                    檢視
                </Button>
            ),
        },
    ];

    return (
        <Layout>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant="h4" sx={{ textAlign: 'left', mb: 5, mt: 2 }}>
                    用量與費用
                </Typography>

                <Card elevation={1} raised sx={{ width: '100%', maxWidth: '1200px' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        padding: '32px',
                        mb: 3
                    }}>
                        <Box mr={2} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateRangePicker
                                    value={dateRange}
                                    onChange={handleDateRangeChange}
                                    localeText={{ start: '開始日期', end: '結束日期' }}
                                    sx={{ width: '430px' }}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box mr={2} mb={2}>
                            <FormControl>
                                <InputLabel id="period-select-label">期間</InputLabel>
                                <Select
                                    labelId="period-select-label"
                                    value={periodSelect}
                                    label="期間"
                                    onChange={handlePeriodSelectChange}
                                    sx={{ width: '220px' }}
                                >
                                    <MenuItem value={1}>當日</MenuItem>
                                    <MenuItem value={7}>一周</MenuItem>
                                    <MenuItem value={30}>一個月</MenuItem>
                                    <MenuItem value={90}>三個月</MenuItem>
                                    <MenuItem value={180}>六個月</MenuItem>
                                    <MenuItem value={365}>一年</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Button
                            variant="contained"
                            onClick={() => initialize()}
                            sx={{
                                backgroundColor: '#CBA660',
                                mb: 2,
                                '&:hover': { backgroundColor: '#B8935C' }
                            }}
                        >
                            搜尋
                        </Button>
                    </Box>

                    <Box sx={{ px: 4, pb: 2 }}>
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <Chip
                                label={`發問次數：${overview.tracesCount} 次`}
                                sx={{
                                    backgroundColor: '#CBA660',
                                    color: 'white',
                                    fontWeight: 'bold'
                                }}
                            />
                            <Chip
                                label={`累積費用：NT$${(overview.totalModelCost).toFixed(1)}`}
                                sx={{
                                    backgroundColor: '#CBA660',
                                    color: 'white',
                                    fontWeight: 'bold'
                                }}
                            />
                        </Box>
                    </Box>

                    <Box width="100%">
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            px: 4,
                            py: 2
                        }}>
                            <Typography variant="body2" color="text.secondary">
                                當日匯率試算：US$1 = NT${overview.usdToTWDRate}
                            </Typography>
                            <Button
                                variant="text"
                                startIcon={<FileDownloadIcon />}
                                onClick={handleExport}
                                sx={{
                                    color: '#CBA660',
                                    '&:hover': { color: '#B8935C' },
                                    textTransform: 'none',
                                }}
                            >
                                匯出
                            </Button>
                        </Box>

                        <DataGrid
                            rows={qaData}
                            columns={columns}
                            rowHeight={72}
                            pagination
                            paginationMode="server"
                            rowCount={totalItems}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            pageSizeOptions={[10, 20, 30, 50]}
                            loading={loading}
                            disableRowSelectionOnClick
                            hideFooterSelectedRowCount
                            aria-label="用量與費用"
                            componentsProps={{
                                basePopper: {
                                    sx: { zIndex: 1300 }
                                }
                            }}
                            sx={{
                                '&.MuiDataGrid-root .MuiDataGrid-container--top [role=row], &.MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]': {
                                    backgroundColor: 'rgba(203, 166, 96, 0.08)',
                                },
                                '& .MuiDataGrid-row': {
                                    borderBottom: 'none',
                                    transitionDuration: '0.3s',
                                    '&:nth-of-type(odd)': {
                                        backgroundColor: '#ffffff'
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: '#ffffff'
                                    },
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                    }
                                },
                                '& .MuiDataGrid-scrollbar': {
                                    '& .MuiDataGrid-scrollbarContent': {
                                        outline: 'none'
                                    }
                                },
                                '& .MuiDataGrid-virtualScrollerContent': {
                                    outline: 'none'
                                }
                            }}
                        />
                    </Box>
                </Card>
            </Box>
            <UsageInspectionDialog
                open={dialogOpen}
                handleClose={handleCloseDialog}
                rowData={selectedRow}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </Layout>
    );
};

export default UsageAndFees;