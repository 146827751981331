import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSignInWithEmailAndPassword} from 'react-firebase-hooks/auth';
import {
    TextField,
    Button,
    Typography,
    Box,
    CircularProgress,
    Snackbar,
    CardMedia,
    Card,
    CardHeader, Paper, IconButton
} from '@mui/material';
import {auth, fetchProvider} from '../../firebase'; // 導入 auth 實例
import API from '../../api/v1/index'
import Logo from '../../assets/images/logo.svg';
import {setLocalStorage} from "../../utils/localStorage";
import tokenManagementService from "../../services/tokenManagement";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const [
        signInWithEmailAndPassword,
        ,
        ,
        signInError
    ] = useSignInWithEmailAndPassword(auth);

    /**
     * @function handleLogin
     * @description 處理登入流程，包括 GCIP 認證和後端 API 登入
     * @param {Event} e - 表單提交事件
     */
    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            // 確保已經獲取到 provider 資訊
            await fetchProvider();

            // 步驟 1: GCIP 認證
            const userCredential = await signInWithEmailAndPassword(email, password);
            if (!userCredential) {
                throw new Error('GCIP 認證失敗');
            }

            const idToken = await userCredential.user.getIdToken();

            // 步驟 2: 後端 API 登入
            const response = await API.login({ idToken });

            // 步驟 3: 檢查 API 回應
            if (response.code === 0 && response.data && response.data.token) {
                // 將 token 存儲到 localStorage
                setLocalStorage('token', response.data.token);
                setLocalStorage('expire', response.data.expire);

                tokenManagementService.setToken(response.data.token, response.data.expire);
                // 登入成功，導航到儀表板
                navigate('/dashboard');
            } else {
                throw new Error('後端 API 回應無效');
            }
        } catch (error) {
            console.error('登入錯誤:', error);
            let errorMessage = '登入失敗。';

            if (signInError) {
                // 處理 GCIP 錯誤
                switch (signInError.code) {
                    case 'auth/user-not-found':
                        errorMessage += '找不到此電子郵件的使用者。';
                        break;
                    case 'auth/wrong-password':
                        errorMessage += '密碼不正確。';
                        break;
                    case 'auth/invalid-email':
                        errorMessage += '無效的電子郵件格式。';
                        break;
                    default:
                        errorMessage += signInError.message;
                }
            } else if (error.response) {
                // 處理後端 API 錯誤
                errorMessage += error.response.data.message || '請稍後再試。';
            } else {
                errorMessage += '請檢查您的憑證並重試。';
            }

            setSnackbarMessage(errorMessage);
            setOpenSnackbar(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box component="form" onSubmit={handleLogin}
             sx={{maxWidth: 520, height: '100vh', margin: 'auto', alignItems: 'center',justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
            <Card
                elevation={0}
                sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
            >
                <CardMedia
                    component="img"
                    image={Logo}
                    sx={{ width: '172px', marginBottom: '48px' }}
                />
                <CardHeader
                    title="登入"
                    sx={{ width: '100%', textAlign: 'left' }}
                />
                <Typography variant="body2" sx={{ width: '100%', color: 'text.secondary', textAlign: 'left' }}>
                    Welcome to Vurbo.agent, please log in to continue
                </Typography>
            </Card>
            <TextField
                fullWidth
                margin="normal"
                label="Email"
                type="email"
                value={email}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <Box sx={{width: '100%', position: 'relative'}}>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <IconButton
                    aria-label={
                        showPassword ? 'hide the password' : 'display the password'
                    }
                    onClick={handleClickShowPassword}
                    edge="end"
                    sx={{position: 'absolute', right: '10px', top: '56%', transform: 'translateY(-50%)' }}
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </Box>
            <Paper elevation={3} sx={{ width: '100%', mt: 2 }}>
                <Button type="submit" variant="contained" fullWidth disabled={isLoading}>
                    {isLoading ? <CircularProgress size={24} /> : '登入'}
                </Button>
            </Paper>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default Login;